import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const SustainabilityPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Seo title="Sustainability" keywords={[`georg gilstein`, `cinematographer`, 'filmemacher', 'kameramann', 'darmstadt', `frankfurt`, `cameraman`, `dop`, `director of photography`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="kg-width-wide-split">
          <GatsbyImage
              image={data.profile.childImageSharp.gatsbyImageData}
              className="kg-image"
              style={{borderRadius:'10px'}}
            />
          <h2 id="sustainability">
          SUSTAINABILITY
          </h2>

          </div>
          <p>
          Even though I'm a big fan of the latest and greatest tech, I'm also aware of the impact it has on our environment.<br /><br />
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profile: file(
      relativePath: { eq: "profile-pic.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1360)
      }
    }
  }
`

const sustainability = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <SustainabilityPage location={props.location} data={data} {...props} />
    )}
  />
)
export default sustainability;